/*-----------------------------------------------------------------------------------
    HEADER / HERO
-----------------------------------------------------------------------------------*/
header.hero-img {
    @include clearfix;
    color: black;
    position: relative;
    z-index: 10;
    padding-top: 200px;

    @media only screen and (min-width: 768px) {
        padding-top: 0;
    }

    @media screen and (min-width:992px) {
        .row {
            display:flex;
            display:-webkit-flex;
            display:-ms-flex;
            .col-xs-12.col-sm-12.col-md-5.col-lg-5 {
                align-self: center;
                -ms-flex-align: center;
            }
        }
    }

    .intro-text {
        .intro-lead-in {
            font-style: normal;
            font-size: 1.5em;
            line-height: 1.3;
            margin-bottom: 25px;
            font-weight: 400;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                font-style: normal;
                font-size: 2em;
                line-height: 1.3;
                margin-bottom: 25px;
            }

            &.small-intro-lead-in {
                font-size: 1.5em;

                @media only screen and (min-width: 960px) {
                    font-size: 1.5em;
                }
            }

        }

        .intro-heading {
            @include heading-font;
            font-weight: 600;
            font-size: 2.5em;
            line-height: 1.125;
            margin-bottom: 25px;
            position: relative;
            display: inline;
            z-index: 1;
            padding: 0 0px;

            @media only screen and (min-width: 768px) {
                font-size: 3.5em;
                margin-bottom: 50px;
            }

            @media only screen and (min-width: 992px) {
                font-size: 4.5em;
                margin-bottom: 50px;
            }

            &.small-heading {
                font-size: 2em;
                line-height: 1.2;

                @media only screen and (min-width: 960px) {
                    font-size: 3em;
                }
            }

            &.txt-white {
                color: #FFF;
            }

        }

        p {
            font-size: 1.875em;
            font-weight: 400;
        }
    }
    @media screen and (max-width: 991px) {
        .img-responsive.img-centered {
            max-width: 300px;
        }
        .row.reversemobile {
                display: flex;
                display: -webkit-flex;
                display: -ms-flex;
                flex-direction: column-reverse;
        }
        .col-xs-12.col-sm-12.col-md-7.col-lg-7 {
           padding-bottom:20px;
        }
    }
}

.index-success {
    .navbar-custom .navbar-brand .brand-text img {
        width: 100px;
        height: auto;
    }
    header {
        height: calc(100vh - 77px);

        h1 {
            font-weight: 600;
            font-size: 2.5em;
            line-height: 1.125;
            margin-bottom: 25px;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Additional STYLE-CLASSES
-----------------------------------------------------------------------------------*/

.verticalAlignedMiddle {
    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 350px;
        //flex-direction: column;

        @media only screen and (min-width: 768px) {
            height: 100vh;
        }
        @media only screen and (max-height: 800px) and (min-width:480px) {
           padding: 150px 0 75px;
           height: auto;
        }
    }
}

.pattern-overlay {
    background: url('../img/pattern/pattern_50.png') repeat;
    width: 100%;
    height: 100%;
}

.background-overlay {
    //background: rgba(255,255,255,.5);
    background: rgb(255, 255, 255);
    background: linear-gradient(rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, .8) 100%);
    width: 100%;
    height: 100%;
    transform: scale(1.11);
}


/*-----------------------------------------------------------------------------------
    SCROLL-BUTTON
-----------------------------------------------------------------------------------*/

.hd-Hero-note {
    display: none;

    @media only screen and (max-height: 800px) {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        letter-spacing: .2em;
        bottom: 0;
        left: 50%;
        padding-left: 70px;
        color: #111;
        font-family: proxima-nova, sans-serif;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        transform: rotate(-90deg) translateY(50%);
        transform-origin: 0 100%;
        display: block;
    }
}

.hd-Hero-note,
.hd-Hero-note:before {
    display: none;

    @media only screen and (min-width: 768px) {
        display: block;
        position: absolute;
        cursor: pointer;
    }
}

.hd-Hero-note:before {
    display: none;

    @media only screen and (min-width: 768px) {
        content: "";
        top: 50%;
        left: 0;
        width: 50px;
        height: 1px;
        background-color: #111;
    }
}






.button {
    cursor: pointer;
    transition: background-color .25s ease-out, box-shadow .25s ease-out;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    letter-spacing: 1.89px;
    text-transform: uppercase;
    padding: 25px 29px 26px;
    margin-right: 30px;
    display: inline-block;
    outline: none;
    text-decoration: none;
}

.button.white {
    background: #fff;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .06);
    border-radius: 2px;
    font-size: 14px;
    color: #00adee;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform .15s ease-in-out;
}

.features .jagged-hero-content .button {
    width: 180px;
}

.button:last-child {
    margin-right: 0;
}

.button.translucent {
    background: hsla(0, 0%, 100%, .11);
    border: 1px solid #fff;
    border-radius: 2px;
    transition: background .2s ease-in-out;
}

.button.translucent {
    background: hsla(0, 0%, 100%, .11);
    border: 1px solid #fff;
    border-radius: 2px;
    transition: background .2s ease-in-out;
}

.features .sub-hero {
    padding-bottom: 30px;
}

.macbook2 {
    display: inline-block;
    max-width: 910px;
    width: 100%;
    margin-bottom: 100px;
}

.features .macbook2 {
    margin-bottom: 160px;
}

.macbook2 .inner {
    padding-top: 53.1868132%;
    position: relative;
}

.macbook2 img {
    position: absolute;
    left: 0;
    top: 0;
}

.logo-stripe {
    margin: 50px 0;
    text-align: center;

    img {
        max-height: 40px;
        padding: 0 20px;
    }
}

.h57 {
    font-size: 28px;
    color: #373636;
    line-height: 51px;
}



.diag {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    transform-origin: 0;
}

.hero-diag {
    transform: skewY(-9deg);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 80%, rgba(237, 240, 242, 1) 100%);
    z-index: 0;
    max-height: 720px;

    @media only screen and (min-width: 768px) {
        max-height: 940px;
    }

    @media only screen and (min-width: 1440px) {
        max-height: 1200px;
    }

}

@media (min-width: 62em) {
    .hero-diag {
        transform: skewY(-10deg);
    }
}

.hero-diag.smartweb-diag {
    max-height: 900px;

    @media only screen and (min-width: 768px) and (max-width: 990px) {
        max-height: 1200px;
    }

    @media only screen and (min-width: 992px) {
        max-height: 1000px;
    }

    @media only screen and (min-width: 1200px) {
        max-height: 1070px;
    }

    @media only screen and (min-width: 2300px) {
        max-height: 1140px;
    }
}


.circle {
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background: #f9fbfe;
    position: absolute;
    right: -200px;
    top: -520px;
}

#kontakt .circle {
    width: 1500px;
    height: 1500px;
    border-radius: 50%;
    background: #f9fbfe;
    position: absolute;
    left: -50px;
    top: 0;
}


/*-----------------------------------------------------------------------------------
    Scroll-Down Line-Animation
-----------------------------------------------------------------------------------*/

.scroll-down {
    @include clearfix();
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;

    @media only screen and (max-width: 768px) {
        display: none;
    }
    @media only screen and (max-height: 800px) {
        display: none;
    }
}

.line {
    width: 1px;
    height: 72px;
    background-color: #ccc;
    display: block;

    &:before {
        content: '';
        width: 1px;
        height: 50%;
        top: 0;
        background-color: #000;
        display: block;
        animation: downWipe 2.2s cubic-bezier(0.76, 0, 0.3, 1) infinite;
    }
}

@keyframes downWipe {
    0% {
        height: 0;
    }

    50% {
        height: 100%;
    }

    70% {
        height: 100%;
        transform: scaleY(0.5);
        transform-origin: bottom;
    }

    100% {
        height: 100%;
        transform: scaleY(0);
        transform-origin: bottom;
    }
}