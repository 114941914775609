// Variables

$brand-primary: #a50034;
$brand-primary-light: #E60048;
$gold: #cdbb88;
$brand-danger: #e74c3c;
$theme-primary:    #a50034;
$gray-darkest: #222;
$gray: #999;
$gray-lighter: #f7f7f7;
$placeholder-text: #bbbbbb;
$body-color: #333;
$trurnit-green: #65AC86;

$brand-primary-gradient: $brand-primary linear-gradient(320deg, $brand-primary-light, $brand-primary);

// linear-gradient(190.34deg, #E60048 18.05%, #A50034 94.36%);