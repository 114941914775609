/*-----------------------------------------------------------------------------------
    EUCOOKIE
-----------------------------------------------------------------------------------*/

#trurnit-eucookie {
    display: none;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .96);
    padding: 2% 5%;
    z-index: 9999;
    font-size: 13px;
    font-weight: 400;
    box-shadow: 0 5px 21px 0 rgba(0, 0, 0, .5);

    @media only screen and (max-width: 530px) {
        padding: 10px;
    }
}

#trurnit-eucookie p {
    margin: 0 20px;
    display: inline-block;
    float: left;
    width: 60%;
    text-align: left;
    font-weight: 400;
    font-size: 1.125em;
    @media only screen and (max-width: 910px) {
        width: 70%;
    }
    @media only screen and (max-width:780px) {
        width: 90%;
    }
    @media only screen and (max-width:500px) {
        width: 65%;
        margin-right: 0;
    }
}

#trurnit-eucookie button {
    float: right;
    background: none;
    border: 1px solid $brand-primary;
    border-radius: 30px;
    outline: 0;
    color: $brand-primary;
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin: 5px;
    padding: 7px 33px;
}

#trurnit-eucookie button:hover,
#trurnit-eucookie button:focus,
#trurnit-eucookie button:active {
    background: $brand-primary;
    color: white;
}

#trurnit-eucookie a,
#trurnit-eucookie a:visited {
    text-decoration: none;
    color: #333;
    transition: 200ms color;
    text-decoration: underline;
}

#trurnit-eucookie a:hover,
#trurnit-eucookie a:active {
    text-decoration: none;
}