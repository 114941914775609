/*-----------------------------------------------------------------------------------
    NAVIGATION
-----------------------------------------------------------------------------------*/

// Custom Navigation Bar
.navbar-default {
    background-color: $gray-darkest;
    border-color: transparent;
    @include box-shadow;

    .navbar-brand {
        color: $brand-primary;
        @include script-font;

        &.active,
        &:active,
        &:focus,
        &:hover {
            color: darken($brand-primary, 10%);
        }
    }

    .navbar-collapse {
        border-color: fade(white, 2%);
    }

    .navbar-toggle {
        background-color: $brand-primary;
        border-color: $brand-primary;

        .icon-bar {
            background-color: white;
        }

        &:focus,
        &:hover {
            background-color: $brand-primary;
        }
    }

    .nav {
        li {
            a {
                @include heading-font;
                font-weight: 400;
                letter-spacing: 1px;
                color: white;

                &:focus,
                &:hover {
                    color: $brand-primary;
                    outline: none;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) {
    .navbar-default {
        background-color: transparent;
        padding: 25px 0;
        transition: all 0.3s ease-in-out;
        border: none;
        box-shadow: none;

        .navbar-brand {
            font-size: 2em;
            transition: all 0.3s ease-in-out;
        }

        .navbar-nav>.active>a {
            border-radius: 3px;
            background: none;
            color: $brand-primary;
        }
    }

    .navbar-default.navbar-shrink {
        background-color: $gray-darkest;
        padding: 10px 0;

        .navbar-brand {
            font-size: 1.5em;
        }
    }
}

// Custom Navigation Bar
.navbar-custom {
    background-color: white;
    border-color: transparent;

    .navbar-brand {
        color: $theme-primary;
        padding: 17px 15px 10px;
        height: 53px;
        @media only screen and (min-width:768px) { 
            height: 50px;
            padding: 15px;
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            color: darken($theme-primary, 10%);
        }
        span {
            display: inline;
        }

        .brand-figure {
            margin-right: 7px;
            img {
                width: 10px;
                height: auto;
                vertical-align: top;
                transition: width 0.3s;

                @media only screen and (min-width:768px) {
                    width: 16px;
                }
            }
        }
        .brand-text {
            img {
                width: 155px;
                height: auto;
                vertical-align: top;
                transition: width 0.3s;

                @media only screen and (min-width:768px) {
                    width: 217px;
                }
            }
        }
    }
    .navbar-collapse {
        border-color: fade(white, 2%);
    }
    .navbar-toggle {
        background-color: white;
        border-color: $theme-primary;
        @include heading-font;
        color: $theme-primary;
        font-size: 12px;
        &:hover,
        &:focus {
            background-color: $theme-primary;
            color: white;
        }
    }
    .nav {
        li {
            a {
                @include heading-font;
                font-weight: 600;
                font-size: .875em;
                letter-spacing: 1px;
                color: $theme-primary;
                &:hover,
                &:focus {
                    color: white;
                    outline: none;
                }
            }
        }
    }
}

@media only screen and (min-width:768px) {
    .navbar-custom {
        background-color: transparent;
        padding: 25px 0;
        transition: padding 0.3s;
        border: none;
        .navbar-brand {
            font-size: 2em;
            transition: all 0.3s;
        }
        .navbar-nav>.active>a {
            border-radius: 3px;
        }
        .nav {
            li {
                a {
                    &:hover,
                    &:focus {
                        color: $theme-primary;
                    }
                }
            }
        }
    }
}

// Navbar Change on Scroll
@media only screen and (min-width:768px) {
    .navbar-custom.affix {
        @include box-shadow;
        background-color: white;
        padding: 10px 0;
        .navbar-brand {
            font-size: 1.5em;
            padding: 0 15px;
            .brand-figure {
                img {
                    width: 50px;
                    height: auto;
                    vertical-align: top;
                    padding: 5px 5px 5px 0;
                }
                margin-right: 7px;
            }
            .brand-text {
                img {
                    width: 0;
                    height: auto;
                    vertical-align: top;
                }
            }
        }
    }
}

/* .navbar-brand {
   background:transparent url('../img/logos/logo-trurnit-digitale-werke.png') 15px 15px no-repeat;
    background-size: 240px;
   width: 270px;
} */