[type="reset"],
[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}

/*-----------------------------------------------------------------------------------
    GLOBAL
-----------------------------------------------------------------------------------*/

body {
    overflow-x: hidden;
    @include body-font;
    font-size: 14px;

    @media only screen and (min-width: 768px) {
        font-size: 16px;
    }
}

// Highlight Color Customization
::-moz-selection {
    color: #fff;
    text-shadow: none;
    background: $brand-primary;
}

::selection {
    color: #fff;
    text-shadow: none;
    background: $brand-primary;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $gray-lighter;
}

b,
strong,
.strong {
    font-weight: 500;
}

.text-muted {
    color: $gray;
}

.text-small {
    font-size: 0.75em;
}

.text-center {
    text-align: center;
}

.text-primary {
    color: $brand-primary;
}

.rounded-circle {
    border-radius: 50%;
}

p,
ol,
ul {
    font-size: 1.3125em;
    font-weight: 300;
    line-height: 1.75;
}

p.large {
    font-size: 2em;
}

a,
a.active,
a:active,
a:focus,
a:hover {
    outline: none;
}

a {
    color: $brand-primary;
}

a.active,
a:active,
a:focus,
a:hover {
    color: darken($brand-primary, 10%);
}

a.text-brand-primary {
    color: $brand-primary !important;
}

a {
    display: inline-block;
    padding: 4px 0;
    position: relative;
    text-decoration: none !important;

    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: currentColor;
        transition: width 0.5s;
        will-change: width;
        right: 0;
        width: 0;
    }

    &:hover {
        &:before {
            left: 0;
            right: auto;
            width: 100%;
        }
    }
}

.page-scroll {
    position: initial;

    &:before {
        display: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include heading-font;
    font-weight: 700;
}

h2 {
    font-size: 2.5em;
}

h2.h2-small {
    font-size: 2em;
    padding: 0.5em 0 0.875em;
}

.head-underlined {
    margin-bottom: 16px;
    display: inline-block;

    &:after {
        content: "";
        height: 1px;
        width: 100%;
        display: inline-block;
        background: $brand-primary;
    }
}

@media screen and (max-width: 992px) {
    .h1,
    .h2,
    .h3,
    h1,
    h2,
    h3 {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}

.nowrap {
    white-space: nowrap;
}

.img-centered {
    margin: 0 auto;
}

.magazine-title {
    width: 100%;
    max-width: 300px;
}

.bg-light-gray {
    background-color: $gray-lighter;
}

.bg-darkest-gray {
    background-color: $gray-darkest;
}

.nl-small {
    font-size: 1.125em;
    font-weight: 400;
}

.heading {
    margin-top: 74px;
    padding: 1em 0;
}

.divider-dotted {
    border-top: 1px dotted #a5b3b4;
}

.divider-big {
    margin: 2.5em 0 3.125em;
}

.flex-center {
    @media only screen and (min-width: 640px) {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 991px) {
    .row.reversemobile {
        display: flex;
        display: -webkit-flex;
        display: -ms-flex;
        flex-direction: column-reverse;
    }
}

.row {
    .margin-bottom-50 {
        margin-bottom: 50px;
    }
}

.narrow-headline {
    max-width: 840px;
    margin: 0 auto;
}

/*-----------------------------------------------------------------------------------
    BUTTONS
-----------------------------------------------------------------------------------*/

.btn {
    white-space: inherit;
}

// Restyled Primary Buttons
.btn-primary {
    @include button-variant(white, $brand-primary, $brand-primary);
    @include heading-font;
    font-weight: 600;
    border-radius: 40px;
    padding: 15px 30px;
    letter-spacing: 0.03em;

    &:before {
        display: none;
    }
}

.btn-xl {
    @include button-variant(white, $brand-primary, $brand-primary);
    @include heading-font;
    font-weight: 500;
    border-radius: 40px;
    border: none;
    font-size: 1em;
    padding: 20px 25px;
    letter-spacing: 0.03em;

    @media (min-width: 768px) {
        font-size: 1.125em;
        padding: 20px 40px;
    }

    &:before {
        display: none;
    }
}

.btn.active,
.btn:active,
.btn:active:focus,
.btn:focus {
    outline: none;
}

.btn-ghost {
    @include button-variant($brand-primary, transparent, $brand-primary);
    font-weight: 600;
    border-radius: 40px;
    padding: 15px 30px;
    letter-spacing: 0.03em;
    background: #fff;

    &:before {
        display: none;
    }
}

button.btn-primary {
    padding: 15px 20px;

    @media only screen and (min-width: 768px) {
        padding: 15px 85px;
    }
}

.btn-shadow {
    box-shadow: 0 10px 27px 0 rgba(172, 38, 74, 0.14);
}

.btn-chat {
    line-height: 1.875;

    .icon-chat-bubble {
        margin: 0 -30px 0 30px;
        height: 25px;
        width: 25px;
        overflow: hidden;
        float: left;

        svg {
            transform: scale(0.046875, 0.046875);
            transform-origin: 0 0;

            path {
                fill: #fff;
            }
        }
    }
}

.btn-whitepaper {
    line-height: 1.875;
}

.btn-blind {
    margin: 20px 24px;
    font-size: 1.125em;
}
/*-----------------------------------------------------------------------------------
    MARGINS
-----------------------------------------------------------------------------------*/

.top-buffer-10 {
    margin-top: 10px;
}

.top-buffer-20 {
    margin-top: 20px;
}

.top-buffer-30 {
    margin-top: 30px;
}

.top-buffer-40 {
    margin-top: 40px;
}

.top-buffer-50 {
    margin-top: 50px;
}

.top-buffer-60 {
    margin-top: 60px;
}

.top-buffer-70 {
    margin-top: 70px;
}

.top-buffer-80 {
    margin-top: 80px;
}

.top-buffer-90 {
    margin-top: 90px;
}

.top-buffer-100 {
    margin-top: 100px;
}

.top-buffer-110 {
    margin-top: 110px;
}

.top-buffer-120 {
    margin-top: 120px;
}

.top-buffer-130 {
    margin-top: 130px;
}

.top-buffer-140 {
    margin-top: 140px;
}

.top-buffer-150 {
    margin-top: 150px;
}

.top-buffer--10 {
    margin-top: -10px;
}

.top-buffer--20 {
    margin-top: -20px;
}

.top-buffer--30 {
    margin-top: -30px;
}

.top-buffer--40 {
    margin-top: -40px;
}

.top-buffer--50 {
    margin-top: -50px;
}

.top-buffer--100 {
    margin-top: -100px;
}

.no-padding {
    padding: 0 !important;
    margin: 0 !important;
}

.padding {
    padding-left: 15px;
    margin-top: 50px;

    @media only screen and (min-width: 992px) {
        padding-left: 70px;
        margin-top: 0;
    }
}

/*-----------------------------------------------------------------------------------
    SECTION
-----------------------------------------------------------------------------------*/

section {
    @include clearfix;
    position: relative;
    padding: 70px 0;

    @media only screen and (min-width: 768px) {
        padding: 70px 0;
    }

    &.reducedpadding {
        padding: 35px 0;
    }

    .container {
        position: relative;
    }

    h2.section-heading {
        font-size: 2.125em;
        margin-top: 0;
        margin-bottom: 15px;

        @media only screen and (min-width: 768px) {
            font-size: 2.5em;
        }
    }

    h3.section-subheading {
        font-size: 16px;
        @include serif-font;
        text-transform: none;
        font-style: italic;
        font-weight: 400;
        margin-bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        padding: 70px 0;
    }
}


/*-----------------------------------------------------------------------------------
    Backgrounds
-----------------------------------------------------------------------------------*/
.bg-linear-gradient {
    background: linear-gradient(to bottom, rgba(255,255,255,1) 20%,rgba(255,255,255,1) 80%,rgba(237,240,242,1) 100%);
}

/*-----------------------------------------------------------------------------------
    LIST STYLES
-----------------------------------------------------------------------------------*/

.listCheck {
    list-style-type: none;

    li {
        position: relative;
        font-size: 0.875em;
        font-weight: 400;
        margin-bottom: 0.875em;
        line-height: 1.5;

        &:before {
            content: "";
            display: inline-block;
            height: 20px;
            width: 20px;
            border-left: 2px solid $brand-primary;
            border-bottom: 4px solid $brand-primary;
            border-top: 1px solid transparent;
            border-right: 1px solid transparent;
            transform: rotate(-45deg) scaleY(0.5);
            position: absolute;
            left: -40px;
        }
    }
}

.listOrdered {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
        counter-increment: step-counter;
        margin-bottom: 10px;
        margin-left: 40px;

        &:before {
            content: counter(step-counter) " ";
            color: $brand-primary;
            font-weight: 600; //padding: 3px 8px;
            margin-left: -42px;
            margin-right: 10px;
            width: 33px;
            height: 33px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            border: 1px solid $brand-primary;
        }
    }
}

/*-----------------------------------------------------------------------------------
    DIGITALE WERKE
-----------------------------------------------------------------------------------*/

.site-wrapper {
    z-index: 999;
    background-color: #fff;
    position: relative;
    border-bottom: 3px solid $gold;
    overflow: hidden;

    @media only screen and (min-width: 768px) {
        margin-bottom: 200px;
    }
}

.footer-wrapper {
    position: relative;
    background-color: #fff;

    @media only screen and (min-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.verticalLine {
    color: #363636;
    display: block;
    font-size: 18px;
    font-weight: 600;
    float: left;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    letter-spacing: 0.1em;
    display: block;
    padding: 15px;
    margin-top: 70px;

    @media only screen and (min-width: 992px) {
        transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform-origin: 0 50%;
        position: absolute;
        bottom: -55%;
        left: 26%;
        float: none;
        display: flex;
        align-items: center;
        padding: 0;
        width: 530px;
        margin-top: 0;
    }

    #inquiry & {
        @media only screen and (min-width: 992px) {
            bottom: -290px;
            width: 400px;
        }
    }
}

.verticalLine-text {
    span {
        display: block;
        font-weight: 300;
    }
}

.verticalLine-form {
    height: 1px;
    width: 100%;
    background: #111;

    @media only screen and (min-width: 992px) {
        margin: 0 20px;
    }
}

.patternDots {
    background: transparent url("../img/dot.svg") repeat 0 0;
    position: relative;
    height: 250px;
    width: 145px;
    position: absolute;
    z-index: -1;
    top: -30px;
}

.cardBox {
    padding: 1em 1.5em;
    box-shadow: 0 0.6rem 4rem rgba(0, 0, 0, 0.08);
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.875;
    margin-top: 48px;

    @media only screen and (min-width: 768px) {
        padding: 2em 3em;
        font-size: 1.5em;
        font-weight: 600;
        line-height: 1.875;
        margin-top: 48px;
    }

    @media only screen and (min-width: 992px) {
        margin-top: 100px;
    }
}

.p-lead {
    font-size: 1.875em;
    line-height: 1.5;

    @media only screen and (min-width: 768px) {
        line-height: 1.875;
    }

    strong {
        color: $brand-primary;
        font-weight: 700;
    }
}

section#inquiry {
    padding: 0;

    @media only screen and (min-width: 992px) {
        padding: 0 0 70px;
    }
}

/*-----------------------------------------------------------------------------------
    WHITE-LABEL-PRODUCTS
-----------------------------------------------------------------------------------*/

.has-parallax {
    will-change: transform;
}

#products {
    margin-top: -50px;
    padding: 0;

    @media only screen and (min-width: 992px) {
        margin-top: 370px;
    }

    .productsItem {
        margin-bottom: 2em;
        @include clearfix;
    }

    .productHeader {
        position: relative;
        z-index: 2;
        transition: transform 800ms ease-out;
    }

    .productNumber {
        font-size: 1.5em;
        font-weight: 800;
        color: #ccc;

        @media only screen and (min-width: 992px) {
            font-size: 2.25em;
        }
    }

    .productTitle {
        font-size: 4em;
        color: $brand-primary;
        letter-spacing: 0.01em;
        position: relative;
        z-index: 2;
        margin: 0;

        @media only screen and (min-width: 992px) {
            font-size: 5em;
        }

        &.txtBr {
            @media only screen and (min-width: 480px) {
                display: none;
            }
        }

        &.txtTg {
            display: none;

            @media only screen and (min-width: 480px) {
                display: block;
            }
        }
    }

    .productInfo {
        p {
            font-size: 1.3125em;
            margin-bottom: 1em;
        }

        li {
            font-size: 0.875em;
            font-weight: 400;
            margin-bottom: 1em;
            line-height: 1.5;
        }

        h5 {
            letter-spacing: 0.1em;
            display: inline-block;
            font-size: 1.125em;
            font-weight: 700;
            margin-top: 32px;
            margin-bottom: 16px;
            position: relative;

            &:after {
                content: "";
                height: 1px;
                width: 100%;
                display: inline-block;
                background: $brand-primary;
            }
        }

        h6 {
            font-size: 1.3125em;
            letter-spacing: 0.03em;
        }
    }

    .productImage {
        img {
            position: relative;
            z-index: 1;
            max-width: 400px;
            width: 80%;
            transform: translateY(-25px);

            @media only screen and (min-width: 768px) {
                transform: translateY(-35px);
            }

            @media only screen and (min-width: 992px) {
                transform: translateY(-50px);
                width: 130%;
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    TEAM
-----------------------------------------------------------------------------------*/

#team {
    padding: 8px 0 0;

    @media only screen and (min-width: 992px) {
        padding: 290px 0 0;
    }
}

.team_item {
    position: relative;
    margin: 0 15px;

    .team_descr {
        position: absolute;
        z-index: 20;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.8);
        transition: all 0.5s ease-in-out;

        .team_descr_cont {
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            opacity: 0;
            transform: translateY(-100%);
            transition: all 0.5s ease-in-out;
        }

        p {
            margin: 0 0 6px;
            font-weight: 300;
            line-height: 24px;
            font-size: 24px;
            color: #fff;
        }

        span {
            color: #999;
        }
    }

    &:hover {
        .team_descr {
            opacity: 1;

            .team_descr_cont {
                opacity: 1;
                transform: translateY(-70%);
            }
        }
    }

    .team_social {
        position: absolute;
        z-index: 30;
        left: 0;
        bottom: 0;
        width: 100%;
        opacity: 0;
        text-align: center;
        transition: bottom 0.5s ease-in-out, opacity 0.5s ease-in-out;

        a:link,
        a:visited {
            color: #fff;
            font-weight: 300;
            padding: 2px;
            margin-bottom: 2px;
            display: inline-block;
            text-decoration: none;
        }

        a:hover,
        a:active,
        a:focus {
            color: #fff;
        }
    }

    &:hover {
        .team_social {
            bottom: 45px;
            opacity: 1;
        }
    }
}

.owl-carousel {
    width: calc(100% - 120px);

    @media only screen and (min-width: 480px) {
        width: calc(100% - 220px);
        margin-left: 110px;
        margin-right: 110px;
    }

    @media only screen and (min-width: 600px) {
        width: calc(100% - 120px);
        margin-left: 60px;
        margin-right: 60px;
    }

    @media only screen and (min-width: 840px) {
        width: calc(100% - 60px);
        margin-left: 30px;
        margin-right: 30px;
    }

    @media only screen and (min-width: 992px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .owl-nav {
        position: absolute;
        left: initial;
        top: 50%;
        width: 100%;
        text-align: left;
        transform: none;

        @media only screen and (min-width: 992px) {
            position: absolute;
            left: 50%;
            top: -80px;
            width: 100%;
            text-align: right;
            transform: translateX(-50%);
        }

        div {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 10px 0 0;
            border-radius: 50%;
            border: 1px solid #f5f5f5;
            box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
                0 1px 1px 0 rgba(0, 0, 0, 0.24);
            transition: all 0.3s ease-in-out;
            will-change: background-color, box-shadow, opacity;
            transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            text-align: center;
            opacity: 0;

            &:hover {
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                    0 6px 3px 0 rgba(0, 0, 0, 0.2);
            }

            &.owl-prev {
                position: absolute;
                left: -53px;

                @media only screen and (min-width: 480px) {
                    left: -100px;
                }

                @media only screen and (min-width: 600px) {
                    left: -53px;
                }

                @media only screen and (min-width: 840px) {
                    left: -30px;
                }

                @media only screen and (min-width: 992px) {
                    position: initial;
                    left: initial;
                }
            }

            &.owl-next {
                position: absolute;
                right: -63px;

                @media only screen and (min-width: 480px) {
                    right: -100px;
                }

                @media only screen and (min-width: 600px) {
                    right: -63px;
                }

                @media only screen and (min-width: 840px) {
                    right: -30px;
                }

                @media only screen and (min-width: 992px) {
                    position: initial;
                    right: initial;
                }
            }

            img {
                position: relative;
                transform: translateY(-50%);
                top: 30px;
                opacity: 0.5;
                width: 40%;
            }
        }
    }

    &:hover {
        .owl-nav {
            div {
                opacity: 1;
                background: #fff;
            }
        }
    }
}

.touchevents {
    .owl-carousel {
        width: calc(100% - 120px);
        margin-left: 60px;
        margin-right: 60px;

        @media only screen and (min-width: 480px) {
            width: calc(100% - 220px);
            margin-left: 110px;
            margin-right: 110px;
        }

        @media only screen and (min-width: 600px) {
            width: calc(100% - 120px);
            margin-left: 60px;
            margin-right: 60px;
        }

        @media only screen and (min-width: 840px) {
            width: calc(100% - 60px);
            margin-left: 30px;
            margin-right: 30px;
        }

        .owl-nav {
            position: absolute;
            left: initial;
            top: 50%;
            width: 100%;
            text-align: left;
            transform: none;

            div {
                opacity: 1;

                &.owl-prev {
                    position: absolute;
                    left: -53px;

                    @media only screen and (min-width: 480px) {
                        left: -100px;
                    }

                    @media only screen and (min-width: 600px) {
                        left: -53px;
                    }

                    @media only screen and (min-width: 840px) {
                        left: -30px;
                    }
                }

                &.owl-next {
                    position: absolute;
                    right: -63px;

                    @media only screen and (min-width: 480px) {
                        right: -100px;
                    }

                    @media only screen and (min-width: 600px) {
                        right: -63px;
                    }

                    @media only screen and (min-width: 840px) {
                        right: -30px;
                    }
                }
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    HeroCTA
-----------------------------------------------------------------------------------*/

#heroCTA {
    padding: 0;
}

.hd-HeroCta {
    display: block;
    margin: 0;
    padding: 20px;
    background-color: $gold;
    line-height: 1.2;
    color: #fff !important;
    text-decoration: none !important;

    strong {
        display: block;
        font-size: 3.4375rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    em {
        position: relative;
        text-decoration: none !important;
        display: inline-block;
        font-family: freight-display-pro, serif;
        font-size: 1.875rem;
        font-style: italic;

        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            height: 2px;
            background-color: currentColor;
            transition: width 0.5s;
            will-change: width;
            right: 0;
            width: 0;
        }
    }
}

.hd-HeroCta:hover em:before,
.hd-HeroCta em.animated:before,
.hd-HeroCta em:hover:before {
    left: 0;
    right: auto;
    width: 100%;
}

.hd-HeroCta-wrapper {
    display: block;
    margin: 0;
    padding: 30px 0 30px 20px;
}

@media only screen and (min-width: 640px) {
    .hd-HeroCta {
        transition: padding 0.3s, margin 0.3s;
    }

    .hd-HeroCta:hover {
        margin: 20px;
        margin-top: 0;
        padding: 0;
        padding-top: 20px;
    }

    .hd-HeroCta strong {
        font-size: 7rem;
    }

    .hd-HeroCta em {
        font-size: 5rem;
    }

    .hd-HeroCta-wrapper {
        padding-top: 52px;
        padding-bottom: 52px;
        padding-left: 60px;
    }
}

@media only screen and (min-width: 1024px) {
    .hd-HeroCta strong {
        display: inline-block;
    }

    .hd-HeroCta-wrapper {
        margin-left: auto;
        margin-right: auto;
        padding-top: 74px;
        padding-bottom: 74px;
        padding-left: 200px;
        max-width: 1140px;
    }
}

.c-team-member {
    position: relative;
    outline: none;
    padding-bottom: 112%;
    margin: 0 15px;
}

.no-touchevents .c-team-member {
    cursor: pointer;
}

.c-team-member_content {
    font-size: 0;
    text-align: center;
    position: absolute;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.6s;
    pointer-events: none;
    padding: 0;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
}

.c-team-member_content:before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
}

.c-team-member_content > * {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
}

.c-team-member.is-active .c-team-member_content,
.no-touchevents .c-team-member:hover .c-team-member_content {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    transition-duration: 0.6s, 0s;
    transition-delay: 0s;
}

.c-team-member.is-active .c-team-member_content::after,
.no-touchevents .c-team-member:hover .c-team-member_content::after {
    transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

@media (max-width: 699px) {
    .c-team-member.is-active .c-team-member_content::after,
    .no-touchevents .c-team-member:hover .c-team-member_content::after {
        transform: translateX(-50%) translateY(0%) scale(3);
    }
}

@media (min-width: 700px) {
    .c-team-member.is-active .c-team-member_content::after,
    .no-touchevents .c-team-member:hover .c-team-member_content::after {
        transform: translateX(-50%) translateY(55%) scale(3);
    }
}

.c-team-member_content::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.9);
    pointer-events: none;
    position: absolute;
    bottom: 0;
    z-index: 0;
    left: 50%;
    transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 50%;
    transform: translateX(-50%) translateY(55%) scale(0);
    padding-bottom: 100%;
    width: 100%;
}

.c-team-member_inner {
    position: relative;
    z-index: 1;
    width: 100%;
}

.no-touchevents .c-team-member_inner {
    cursor: pointer;
}

.c-team-member.is-active .c-team-member_inner > :nth-child(1),
.no-touchevents .c-team-member:hover .c-team-member_inner > :nth-child(1) {
    transition-delay: 0.3s;
}

.c-team-member.is-active .c-team-member_inner > :nth-child(2),
.no-touchevents .c-team-member:hover .c-team-member_inner > :nth-child(2) {
    transition-delay: 0.35s;
}

.c-team-member.is-active .c-team-member_inner > :nth-child(3),
.no-touchevents .c-team-member:hover .c-team-member_inner > :nth-child(3) {
    transition-delay: 0.4s;
}

.c-team-member.is-active .c-team-member_inner > :nth-child(4),
.no-touchevents .c-team-member:hover .c-team-member_inner > :nth-child(4) {
    transition-delay: 0.45s;
}

.c-team-member_name,
.c-team-member_position,
.c-team-member_info {
    margin: 0;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1),
        transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.c-team-member.is-active .c-team-member_name,
.no-touchevents .c-team-member:hover .c-team-member_name,
.c-team-member.is-active .c-team-member_position,
.no-touchevents .c-team-member:hover .c-team-member_position,
.c-team-member.is-active .c-team-member_info,
.no-touchevents .c-team-member:hover .c-team-member_info {
    opacity: 1;
    transform: translateY(0%);
}

.c-team-member_name,
.c-team-member_position {
    line-height: 1.2;
    margin-bottom: 15px;
}

.c-team-member_position {
    font-size: 20px;
    text-transform: uppercase;
}

@media (max-width: 699px) {
    .c-team-member_position {
        margin-top: 20px;
    }
}

@media (min-width: 700px) and (max-width: 1280px) {
    .c-team-member_position {
        margin-top: 1.171875vw;
    }
}

@media (min-width: 1281px) {
    .c-team-member_position {
        margin-top: 0.5208333333vw;
    }
}

.c-team-member_info {
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 699px) {
    .c-team-member_info {
        margin-top: 20px;
    }
}

@media (min-width: 700px) and (max-width: 1280px) {
    .c-team-member_info {
        margin-top: 20px;
    }
}

@media (min-width: 1281px) {
    .c-team-member_info {
        margin-top: 1.0416666667vw;
    }
}

.c-team-member_info a {
    color: #404040;
}

.c-team-member_info a:hover {
    color: #fbc5d8;
}

.c-team-member_abbr {
    text-decoration: none !important;
    font-family: "NoeText", sans-serif;
    font-weight: 600;
}

.c-team-member_photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

@media (max-width: 699px) {
    .c-team-member_button {
        background-color: #ffffff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        bottom: 18px;
        left: calc(50% - 20px);
        z-index: 1;
        -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
            -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .no-touchevents .c-team-member:hover .c-team-member_button {
        opacity: 0;
    }

    .c-team-member.is-active .c-team-member_button {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .c-team-member_button::before,
    .c-team-member_button::after {
        content: "";
        background-color: #fbc5d8;
        position: absolute;
    }

    .c-team-member_button::before {
        right: 12px;
        left: 12px;
        top: calc(50% - 1px);
        height: 2px;
    }

    .c-team-member_button::after {
        top: 12px;
        bottom: 12px;
        left: calc(50% - 1px);
        width: 2px;
    }
}

@media (min-width: 700px) {
    .c-team-member_button {
        display: none;
    }
}

.fadein {
    -webkit-animation: fadein 2s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s;
    /* Firefox < 16 */
    -ms-animation: fadein 2s;
    /* Internet Explorer */
    -o-animation: fadein 2s;
    /* Opera < 12.1 */
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.section-diag {
    background: #f5f7f9;
    height: 400px;
    width: 100%;
    display: block;
    position: absolute;
    transform: skewY(10deg);
    top: 460px;
}

#vorteile {
    li {
        font-size: 0.875em;
        font-weight: 400;
        margin-bottom: 1em;
        line-height: 1.5;
    }
}

/* Youtube-Video*/

.mockup-youtube {
    @include clearfix;
    width: 100%;
    height: 100%;
    max-width: 900px;
    max-height: 473px;
    margin: 0 auto;
}

.laptop-wrapper-youtube {
    position: relative;
    padding-top: 25px;
    padding-bottom: 67.5%;
    height: 0;
}

.laptop-wrapper-youtube iframe {
    box-sizing: border-box;
    background: url("../img/chatbot-macbook-2.png") center center no-repeat;
    background-size: contain;
    padding: 11.9% 15.5% 14.8%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mockup {
    @include clearfix;
    width: 100%;
    height: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.laptop-wrapper {
    position: relative;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;

    @media only screen and (min-width: 768px) {
        top: 0px;
    }
}

.laptop-wrapper .poster {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: url(../img/video_placehoder.svg) center center no-repeat;
    background-size: contain;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 510px;
}

#customer {
    padding-top: 0;
    padding-bottom: 0;
}

.PlayButton {
    letter-spacing: 0.025em;
    width: 60px;
    height: 60px;
    display: block;
    margin: 0;
    padding: 0;
    border: solid #fff;
    border-width: 1px;
    border-radius: 100%;
    background-color: hsla(0, 0%, 100%, 0.35);
    box-shadow: 0 0 21px rgba(0, 0, 0, 0.1);
    line-height: 3.625rem;
    color: #a50034;
    font-family: inherit;
    font-size: 0.625rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    cursor: pointer;
    animation: a 3s infinite;
    width: 100px;
    height: 100px;
    line-height: 6.125rem;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    &:before {
        display: none;
    }
}

.PlayButton:focus,
.PlayButton:hover {
    outline: none;
    animation: a 3s infinite;
}

@keyframes a {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.top-angle-gray:before,
.top-angle-dark-gray:before,
.top-angle-grad-purple-dark:before,
.top-angle-white:before {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    content: "";
    transform: skewY(5deg);

    background-color: #f5f7f9;
    margin-top: 70px;
}

.column-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.column-buttons-wrapper .column.buttons {
    margin-top: 12px;
    padding-top: 0;
}

.column.text h2 {
    margin-bottom: 13px;
}

.footer-cta {
    z-index: 1;
    padding: 150px 0 70px;
}

.footer-cta p {
    margin: 0;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: -0.01em;
}

/*-----------------------------------------------------------------------------------
    Vorteile
-----------------------------------------------------------------------------------*/

#vorteile,
#vorlagen {
    padding-top: 0;

    @media only screen and (min-width: 768px) {
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .top-buffer-100 {
        margin-top: 50px;

        @media only screen and (min-width: 768px) {
            margin-top: 100px;
        }
    }

    .advantages-wrapper {
        display: flex;
        display: -webkit-flex;
        display: -ms-flex;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        margin: 0 auto;
        .advantages-inner {
            min-height: 160px;
            box-shadow: 0px 36px 50px rgba(74, 122, 255, 0.1);
            background: #fff;
            text-align: center;

            /*
            margin-right: 15px;
            margin-left: 15px;
            margin-bottom:15px;
            margin-top:15px;
            */
            margin: 15px 15px 15px 15px;
            @media screen and (min-width: 1200px) {
                max-width: calc(25% - 30px);
            }
            @media screen and (min-width: 800px) and (max-width: 1200px) {
                max-width: calc(33.33% - 30px);
                flex: 1 0 auto;
            }
            @media screen and (min-width: 481px) and (max-width: 800px) {
                max-width: calc(50% - 30px);
            }
            @media screen and (min-width: 480px) {
                max-width: 100%;
            }
            margin-bottom: 30px;
            display: grid;
            display: -ms-grid;
            padding-top: 35px;
            padding-bottom: 10px;
            img {
                margin: 0 auto;
            }
            h3 {
                text-transform: none;
                font-size: 1.25em;
                font-weight: 600;
            }
            > div:nth-child(2) {
                max-width: 250px;
                text-align: center;
                margin: 0 auto;
            }
        }
    }
}

.img-vorteile {
    overflow: hidden;
    margin-bottom: 2em;

    @media only screen and (min-width: 768px) {
        text-align: center;
        margin-bottom: 0;
    }

    img {
        width: 50%;
    }
}

/*-----------------------------------------------------------------------------------
    KONTAKT
-----------------------------------------------------------------------------------*/

#kontakt {
    background: #f5f7f9;
    padding: 0 0 70px;

    background: none;
    padding: 0;

    h3 {
        margin-bottom: 1em;
    }

    .hd-HeroCta.content {
        background: none;
        color: #333;

        strong {
            color: #333;

            @media only screen and (min-width: 640px) {
                font-size: 2.5em;
                margin-right: 30px;
            }
        }

        em {
            color: #333;

            @media only screen and (min-width: 640px) {
                font-size: 2.5em;
            }
        }
    }
    .contact-person-img {
        border-bottom: 1px solid #a50034;
    }
    .flex-center {
        @media only screen and (min-width: 640px) {
            display: flex;
            //align-items: flex-end;
        }
    }
    @media screen and (min-width: 640px) and (max-width: 991px) {
        .row.reversemobile {
            display: flex !important;
            display: inline-flex !important;
            flex-direction: inherit !important;
        }
    }
}

.expert-image {
    overflow: hidden;
    padding: 0 20%;

    @media only screen and (min-width: 640px) {
        padding: 0;
    }

    img {
        width: 100%;
        height: auto;
    }

    &.big {
        display: flex;
        justify-content: center;
        @media screen and (max-width: 992px) {
            display: block;
        }
    }
}

.contact-information {
    text-align: center;

    @media only screen and (min-width: 640px) {
        text-align: left;
    }
}

.contact-information__title {
    margin-top: 1em;

    @media only screen and (min-width: 640px) {
        margin-top: 0;
    }
}

.contact-information__links {
    a {
        margin-bottom: 0.25em;

        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            height: 1px;
            background-color: currentColor;
            -webkit-transition: width 0.5s;
            transition: width 0.5s;
            will-change: width;
            left: 0;
            width: 100%;
        }

        &:hover {
            &:before {
                left: auto;
                right: 0;
                width: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.contact-information__text {
    font-size: 1em;
    margin-top: 2em;
}

.stoerer-circle {
    border-radius: 50%;
    background-color: rgb(165, 0, 52);
    box-shadow: 0px 10px 27px 0px rgba(172, 38, 74, 0.14);
    width: 188px;
    height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    position: absolute;
    bottom: -40px;
    left: 55%;

    @media only screen and (min-width: 962px) {
        left: 90%;
    }

    .stoerer-descr {
        text-align: center;
        font-size: 21px;
        font-weight: 300;
        color: #fff;
    }

    span {
        display: block;
        font-weight: 600;
        font-size: 24px;
    }
}

.icon-headline {
    height: 60px;
    width: 60px;

    &.icon-headline-centered {
        margin: 0 auto;
    }

    svg {
        path {
            fill: #69b0cf;
        }
    }
}

.section-facts {
    margin-top: 50px;

    @media only screen and (min-width: 992px) {
        margin-top: 0;
    }
}

#smartweb-referenzen {
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 1) 80%,
        rgba(237, 240, 242, 1) 100%
    );
    padding-bottom: 100px;

    @media only screen and (min-width: 768px) {
        padding-bottom: 150px;
    }
}

#smartweb-testimonials {
    &:after {
        content: "";
        display: block;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 1) 20%,
            rgba(255, 255, 255, 1) 80%,
            rgba(237, 240, 242, 1) 100%
        );
        transform: skewY(-8deg);
        position: absolute;
        bottom: -310px;
        height: 400px;
        width: 100%;
    }
}

.bottom-info {
    background: $brand-primary;
    color: #fff;
    box-shadow: 0px 10px 50px #939fa9;
    text-align: center;
    padding: 40px;
    position: relative;

    @media only screen and (min-width: 768px) {
        padding: 80px;
    }

    .h3--offer {
        font-weight: 500;
        text-transform: none;
        font-size: 28px;
        line-height: 30px;
        padding: 0;

        @media only screen and (min-width: 768px) {
            font-size: 36px;
            line-height: 46px;
            padding: 0 50px 0px 0px;
        }
    }

    p {
        margin: 30px auto;
        line-height: 26px;
    }

    #sign {
        color: $brand-primary;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        display: inline-block;
        border-radius: 60px;
        padding: 20px 40px;
        background: #fff;
        box-shadow: 0px 5px 20px rgba(51, 73, 90, 0.2);
        transition: all 0.8s ease;
        box-sizing: border-box;

        &:before {
            display: none;
        }

        &:hover {
            box-shadow: 0px 20px 80px darken($brand-primary, 10%);
        }
    }
    @media screen and (min-width: 992px) {
        .bottom-info-headline {
            max-width: 60%;
        }
    }
}

@media screen and (max-width: 992px) {
    .margin-bottom-mobile {
        margin-bottom: 30px;
    }
}

/*-----------------------------------------------------------------------------------
    LOGO-GRID
-----------------------------------------------------------------------------------*/
.logo_grid {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    max-width: 50em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 1.875em 2.5em;

        width: 47.5%;

        img {
            display: block;
            position: relative;
            margin: 0 auto;
            transition: all 0.5s ease;
            max-width: 140px;
            transform: scale(0.9);

            @media only screen and (min-width: 600px) {
                max-width: 200px;
            }

            &:hover {
                transform: scale(1);
            }
        }
        a:before {
            display: none;
        }
    }

    @media only screen and (min-width: 40em) {
        max-width: 50em;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        li {
            width: 47.5%;
        }
    }

    @media only screen and (min-width: 80em) {
        max-width: 100%;

        li {
            width: 25%;
            padding: 1.5em 0 1.875em;

            .testimonial_contain {
                top: 0;
                left: 50%;
                width: 200%;
                transform: translateX(-50%);
            }

            &:nth-child(1),
            &:nth-child(6) {
                img {
                    margin: 0;
                }

                .testimonial_contain {
                    text-align: left;
                    top: 0;
                    left: -2.5em;
                    transform: none;
                }
            }

            &:nth-child(5),
            &:nth-child(10) {
                img {
                    margin: 0 0 0 auto;
                }

                .testimonial_contain {
                    text-align: left;
                    top: 0;
                    left: auto;
                    right: -2.5em;
                    transform: none;
                }
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    Testimonial
-----------------------------------------------------------------------------------*/
.slider-testimonial {
    transition: all 0.3s ease-in-out;
    .slick-list {
        padding: 0 40px;
    }
    .hd-quote {
        text-align: center;
    }

    .hd-quote__author {
        text-align: center;
        padding: 0;

        &:before {
            display: none;
        }
    }

    .slick-arrow {
        // background: none;
        // border: none;
        // outline: none;
        // text-indent: 9999px;
    }

    .slick-next,
    .slick-prev {
        font-family: unset;
        font-size: unset;
        line-height: 1;

        width: 50%;
        height: 100%;
        top: 30%;
        z-index: 3;

        &:before {
            font-family: unset;
            font-size: unset;
            line-height: 1;
            color: transparent;
            padding: 4px;
            box-shadow: inset 2px -2px 0 1px #d1d1d1;
            cursor: pointer;
            border-left-color: #b1b1b1;
            border-right-color: #b1b1b1;
            position: absolute;
            transition: all 0.3s ease-in-out;
        }
    }
    .slick-prev {
        &:before {
            transform: rotate(45deg);
            left: 0;
        }
        &:hover {
            &:before {
                left: -10px;
            }
        }
    }
    .slick-next {
        &:before {
            transform: rotate(225deg);
            right: 0;
        }
        &:hover {
            &:before {
                right: -10px;
            }
        }
    }   
}

.slick-dots {
    text-align: center;
    padding-top: 15px;
    bottom: -65px;

    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 8px;
        pointer-events: auto;

        button {
            width: 24px;
            height: 24px;
            border: none;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid #fff;
            box-shadow: 0 0 0 0 transparent;
            vertical-align: middle;
            color: #fff;
            background-color: #ddd;
            transition: all 0.3s ease;
            opacity: 0.4;

            &:focus {
                outline: none;
            }

            &:hover {
                opacity: 1;
            }

            &:before {
                display: none;
            }
        }

        &.slick-active {
            button {
                background-color: #fff;
                border-color: $brand-primary;
                box-shadow: 0 0 0 2px #fff;
                opacity: 1;
            }
        }
    }
}

.slick-dots li.slick-active,
.slick-dots li.slick-active + li,
.slick-dots li.slick-active + li + li {
    display: inline-block;
}

.csslider > .arrows {
    position: absolute;
    left: -31px;
    top: 50%;
    width: 100%;
    height: 26px;
    padding: 0 31px;
    z-index: 0;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
.csslider > .arrows label {
    display: none;
    position: absolute;
    top: -50%;
    padding: 13px;
    box-shadow: inset 2px -2px 0 1px #d1d1d1;
    cursor: pointer;
    -moz-transition: 0.15s;
    -o-transition: 0.15s;
    -webkit-transition: 0.15s;
    transition: 0.15s;
}
.csslider > .arrows label:hover {
    box-shadow: inset 3px -3px 0 2px #0097ef;
    margin: 0 0px;
}
.csslider > .arrows label:before {
    content: "";
    position: absolute;
    top: -100%;
    left: -100%;
    height: 300%;
    width: 300%;
}
.csslider.infinity > input:first-of-type:checked ~ .arrows label:last-of-type,
.csslider > input:nth-of-type(1):checked ~ .arrows label:nth-of-type(0),
.csslider > input:nth-of-type(2):checked ~ .arrows label:nth-of-type(1),
.csslider > input:nth-of-type(3):checked ~ .arrows label:nth-of-type(2),
.csslider > input:nth-of-type(4):checked ~ .arrows label:nth-of-type(3),
.csslider > input:nth-of-type(5):checked ~ .arrows label:nth-of-type(4),
.csslider > input:nth-of-type(6):checked ~ .arrows label:nth-of-type(5),
.csslider > input:nth-of-type(7):checked ~ .arrows label:nth-of-type(6),
.csslider > input:nth-of-type(8):checked ~ .arrows label:nth-of-type(7),
.csslider > input:nth-of-type(9):checked ~ .arrows label:nth-of-type(8),
.csslider > input:nth-of-type(10):checked ~ .arrows label:nth-of-type(9),
.csslider > input:nth-of-type(11):checked ~ .arrows label:nth-of-type(10) {
    display: block;
    left: 0;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.csslider.infinity > input:last-of-type:checked ~ .arrows label:first-of-type,
.csslider > input:nth-of-type(1):checked ~ .arrows label:nth-of-type(2),
.csslider > input:nth-of-type(2):checked ~ .arrows label:nth-of-type(3),
.csslider > input:nth-of-type(3):checked ~ .arrows label:nth-of-type(4),
.csslider > input:nth-of-type(4):checked ~ .arrows label:nth-of-type(5),
.csslider > input:nth-of-type(5):checked ~ .arrows label:nth-of-type(6),
.csslider > input:nth-of-type(6):checked ~ .arrows label:nth-of-type(7),
.csslider > input:nth-of-type(7):checked ~ .arrows label:nth-of-type(8),
.csslider > input:nth-of-type(8):checked ~ .arrows label:nth-of-type(9),
.csslider > input:nth-of-type(9):checked ~ .arrows label:nth-of-type(10),
.csslider > input:nth-of-type(10):checked ~ .arrows label:nth-of-type(11),
.csslider > input:nth-of-type(11):checked ~ .arrows label:nth-of-type(12) {
    display: block;
    right: 0;
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}
