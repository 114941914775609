/* FORMS ------------------------------------------------------------------------------------------------------------------------------------*/


/* form starting stylings ------------------------------- */

.trurnit-forms {
    .group {
        position: relative;
        margin: 15px 15px 40px;

        &.inline-style {
            margin-bottom: 20px;
        }
    }

    input[type='text'] {
        font-size: 1.125em;
        padding: 10px 10px 5px 5px;
        display: block;
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid $gray;
        border-radius: none;
        color: #000;
        font-weight: 500;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }

        &:focus {
            outline: none;
        }
    }

    label {
        /* @include serif-font;
        color: $brand-primary; */
        font-size: 1.3125em;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 0;
        transition: 0.2s ease all;

        &.error {
            @include body-font;
            font-size: .875em;
            top: 40px;
            color: $gray;
        }
    }

    p {
        margin-bottom: 0;
    }

    .form-disclaimer {
        font-size: .875em;
        line-height: 1.3;
    }
}




/* LABEL ======================================= */


/* active state */

.trurnit-forms input[type='text'].inputField:focus~label,
.trurnit-forms input[type='text'].inputField:valid~label {
    top: -15px;
    font-size: 14px;
    color: #333;

    &.error {
        top: 40px;
        font-size: 12.25px;
    }
}




/* BOTTOM BARS ================================= */

.trurnit-forms .bar {
    position: relative;
    display: block;
    width: 100%;
}

.trurnit-forms .bar:before,
.bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $brand-primary;
    transition: 0.2s ease all;
}

.trurnit-forms .bar:before {
    left: 50%;
}

.trurnit-forms .bar:after {
    right: 50%;
}




/* active state */

.trurnit-forms input[type='text']:focus~.bar:before,
.trurnit-forms input[type='text']:focus~.bar:after {
    width: 50%;
}




/* HIGHLIGHTER ================================== */

.trurnit-forms .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}




/* active state */

.trurnit-forms input[type='text']:focus~.highlight {
    animation: inputHighlighter 0.3s ease;
}




/* ANIMATIONS ================ */

@keyframes inputHighlighter {
    from {
        background: $brand-primary;
    }

    to {
        width: 0;
        background: transparent;
    }
}

.trurnit-forms #contact-form-result {
    padding: 50px 0;
    font-size: 1.2em;
    display: none;
}

.trurnit-forms input[type='text'].error {
    //border-color: #e62220;
}

.trurnit-forms input[type='text']:focus.error {
    border-color: $gold;
}

.trurnit-forms .contactErrorMessage,
.trurnit-forms .newsletterErrorMessage {
    color: #e62220;
    padding: 0;
    margin-bottom: 40px;
    border: 1px solid transparent;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 500;
    display: none;
}

.trurnit-forms {
    .button {
        width: 40%;
        margin-top: 20px;
        padding: 15px;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }

        @media only screen and (max-width:480px) {
            width: 100%;
        }
    }
}

#contact-form-result-success,
#contact-form-result-error {
    padding: 30px 8%;
    display: none;
}





/* 
 * Core styles required for the checkboxes.
 * @author Jason Mayes 2014, www.jasonmayes.com
*/

.trurnit-forms {
    .wskLabel {
        cursor: pointer;
        font-size: .938em;
        display: block;
        overflow: hidden;
        padding-top: 20px;
        position: relative;
        min-height: 40px;
        pointer-events: visible;
        margin-left: -25px;

        .wskLabelCheckbox {
            @include body-font;
        }
    }

    .wskCheckbox {
        border: 1px solid $gray;
        color: #1d1d1d;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 17px;
        margin: 0 20px 70px 20px;
        outline-color: #eaeaea;
        padding: 0;
        position: relative;
        width: 17px;
        transition: all 0.3s ease-in-out;
        z-index: 1;
    }

    .wskCheckbox--normal {
        margin: 0 20px 0 20px;
    }

    .wskCheckbox.checked {
        border-left: 2px solid green;
        border-bottom: 4px solid green;
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-right: 1px solid rgba(0, 0, 0, 0);
        transform: rotate(-45deg) scaleY(0.5);
    }

    input[type='checkbox'].wskCheckbox {
        display: none;
    }

    .wskCircle {
        background-color: #ccdffd;
        border-radius: 50%;
        display: block;
        height: 40px;
        left: 9px;
        opacity: 0;
        position: absolute;
        top: 9px;
        transition: all 0.15s ease-in-out;
        width: 40px;
        z-index: 0;
    }

    .wskCircle.show {
        opacity: 1;
    }

    .wskCircle.flipColor {
        background-color: #d2d3d2;
    }
}


.trurnit-forms {
    .group {
        &.input-checkbox {
            margin-top: 0;
            background: #f5f5f5;
            margin: 0;
            padding: 1px 15px 5px;

            h5 {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .wskLabel {
                font-size: 1.25em;
                padding-top: 10px;
                margin-bottom: 0;

                .wskLabelCheckbox {
                    top: -3px;
                    position: relative;

                    &.wskLabelCheckbox--small {
                        font-size: .9375em;
                    }
                }
            }

            .wskLabel--bigger {
                .wskCheckbox {
                    margin-bottom: 35px;
                }
            }

            .wskLabel--smaller {
                .wskCheckbox {
                    margin-bottom: 10px;
                }
            }

            .wskCheckbox {
                margin: 0 20px 20px 20px;
            }

            .wskCircle {
                top: 0;
            }
        }
    }
}


$md-radio-checked-color: $brand-primary;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }

    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}

.md-radio {
    margin: 16px 0;

    &.md-radio-inline {
        display: inline-block;
    }

    input[type="radio"] {
        //-webkit-appearance: radio!important;
        display: none;

        &:checked+label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }

        &:checked+label:after {
            transform: scale(1);
        }
    }

    input[type="radio"]+label {
        display: inline-block;
        height: $md-radio-size;
        position: relative;
        pointer-events: inherit;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        line-height: $md-radio-size;

        &:before,
        &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }

        &:after {
            top: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
            left: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
            width: $md-radio-checked-size;
            height: $md-radio-checked-size;
            transform: scale(0);
            background: $md-radio-checked-color;
        }
    }
}


#anrede label.error {
    display: block;
    width: 100%;
    height: 20px;
}

label#newsletter {
    display: block;
    width: 100%;
    height: 20px;
    position: initial;
    margin-left: 40px;

    span {
        display: none;
    }
}

.check_angebot_zusatzinfo {
    display: none;
    position: relative;
}

.padding-left-50 {
    margin-left: 35px;
    margin-top: 15px;
    padding-bottom: 25px;
}

.padding-0-0-25-35 {
    margin-left: 35px;
    margin-top: 0;
    padding-bottom: 25px;
}




label#check_box-error {
    padding-top: 0px;
    padding-left: 10px;
}

.input-checkbox .error {
    padding-top: 0px;
    padding-left: 10px;
}

.trurnit-forms {
    .group {
        &.input-checkbox {
            padding: 1px 15px 20px;

            .wskLabel--bigger {
                .wskCheckbox {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.trurnit-forms label.error {
    color: $brand-primary;
}

/*-----------------------------------------------------------------------------------
    FORMBOX
-----------------------------------------------------------------------------------*/

.formBox {
    background-color: rgb(255, 255, 255);
    @include box-shadow;
    box-sizing: border-box;
    padding-bottom: 10px;

    .formHeader {
        background: $brand-primary;
        color: white;
        padding: 5px 15px;
        position: relative;
        margin-bottom: 40px;

        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $brand-primary;
            position: absolute;
            bottom: -20px;
        }

        h4 {
            text-transform: none;
            font-weight: 500;
        }
    }
}

.formHeader--popup {
    background: none;
    color: $body-color;
    margin-top: 30px;

    @media only screen and (min-width: 768px) {
        margin-top: 0px;
    }

    &:after {
        display: none;
    }

    h4 {
        text-transform: none;
        font-weight: 500;
        font-size: 1.875em;
        line-height: 1.3;
        margin-bottom: .5em;
        text-align: center;
    }

    p {
        text-align: center;
        margin-bottom: 1.5em;

        &.form-subhead {
            font-size: 1em;
        }
    }
}