$sm-breackpoint: 769px;


/*-----------------------------------------------------------------------------------
    Index-Onlinekurs-Korrespondenz
-----------------------------------------------------------------------------------*/


.container {
    @media (min-width: 1300px) {
        width: 1280px;
    }
}

h1,
h2,
h3 {
    text-transform: uppercase;
    font-weight: 600;
}

.h1 {
    font-size: 2.25em;
    line-height: 106%;
    font-weight: 700;
}

h2 {
    font-weight: bold;
    font-size: 1.85em;
    line-height: 1.3;
    margin-bottom: .5em;
}

.h2--special {
    font-size: 3em;
    line-height: 1.5;
    font-weight: 300;
    text-transform: none;
}

h4 {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 1.5;
    margin-bottom: 1em;
    text-transform: none;
    @media screen and (max-width:991px) {
        margin-bottom: 0.5em;
    }
}

p {
    font-size: 1.3125em;
    line-height: 170%;
}

strong {
    color: inherit;
    font-weight: 500;
}

ol,
ul {
    font-size: 1em;
    font-weight: 300;
    line-height: 1.75;
}

.btn-xl {
    background: linear-gradient(190.34deg, #E60048 18.05%, #A50034 94.36%);
    box-shadow: 4px 10px 20px rgba(165, 0, 52, 0.2);
}

.img-shadow {
    @include box-shadow();
}


.listCheck {
    li {
        font-size: 1.25em;
        line-height: 32px;
        font-weight: 300;
        @media screen and (max-width:992px) {
            text-align:left;
        }

        &:before {
            top: 7px;
        }
    }
}



.row-eq-height {
    @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }
}

.alignCenter {
    align-items: center;
}

.col-12 {
    width: 100%;
    float: none;
}

/*-----------------------------------------------------------------------------------
    HERO
-----------------------------------------------------------------------------------*/
header.hero-img {
    overflow: hidden;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5em;
    padding-top: 150px;

    @media only screen and (min-width: 992px) {
        height: 100vh;
    }

    @media screen and (max-width:992px) {
        padding:150px 1em 0px 1em;
    }


    .hero-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        /* background: url('../img/hero-smart-illu-2.png') center center no-repeat; */
        background-size: cover;
        animation-name: zoom;
        animation-duration: 30s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        will-change: transform;

        &:after {
            content: '';
            background: linear-gradient(rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .7) 50%, rgba(255, 255, 255, .9) 75%, rgba(255, 255, 255, 1) 100%);
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1000;
            bottom: 0;
            display: block;
            top: auto;
        }
    }

    .intro-text {
        color: #000;
        text-align:left;
        @media screen and (max-width:991px) {
            text-align:center;
        }

        .intro-heading {
            /* @include linear-gradient-text(); */
            font-weight: 700;
            font-size: 2em;

            @media only screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                @include linear-gradient-hide();
            }

            @media only screen and (min-width: 768px) {
                font-size: 4em;
                margin: 0 auto;
                text-transform: uppercase;
            }

            @media only screen and (min-width:992px) {
                font-size: 4em;
                margin: 0 auto;
                text-transform: uppercase;
            }

        }

        .intro-lead-in {
            font-size: 1.875em;
            text-transform: none;

            @media only screen and (min-width: 768px) {
                margin: 1.25em 0px;
                font-weight: 400;
                font-size: 1.5em;
                line-height: 28px;
            }
            @media screen and (min-width: 992px) {
                max-width: 620px;
            }
        }

        h4.intro-lead-in {
            text-transform: uppercase;
        }


        .text-small {
            font-size: 24px;
            margin: 1em auto;
            letter-spacing: 0.54px;
            text-transform: uppercase;
            font-weight:normal;
        }
    }
}

.video-container {
    background: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .75) 50%, rgba(255, 255, 255, 1) 100%);

    .title-container {
        bottom: initial;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .intro-text {
            color: #000;

            .intro-heading {
                font-weight: 700;

                @media only screen and (min-width: 992px) {
                    font-size: 8em;
                    margin: 0 auto;
                    text-transform: uppercase;
                }

            }

            .intro-lead-in {
                font-size: 1.5em;
                max-width: 850px;
                text-transform: none;

                @media only screen and (min-width: 768px) {
                    margin: 1.25em auto;
                    font-weight: 400;
                    font-size: 1.5em;
                    line-height: 1.3;
                }
            }

            h4.intro-lead-in {
                text-transform: uppercase;
            }


            .text-small {
                font-size: 1em;
                margin: 1em auto;
            }
        }
    }
}

@keyframes zoom {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.3);
    }
}


.index-success {
    header.hero-img {
        height: auto;

        @media only screen and (min-width: 992px) {
            height: 100vh;
        }

        .intro-text {

            .intro-heading {

                @media only screen and (min-width: 992px) {
                    font-size: 6em;
                }

            }
        }
    }

    .verticalAlignedMiddle>div {
        flex-direction: column;
    }
}

/*-----------------------------------------------------------------------------------
    Shapes
-----------------------------------------------------------------------------------*/
.shape-one {
    position: absolute;
    left: -1%;
    top: 0;
    right: auto;
    max-width: 221px;
    z-index: -1;
}

.shape-two {
    position: absolute;
    right: -1%;
    top: -25%;
    left: auto;
    bottom: 0;
    z-index: -1;
}

.shape-three {
    position: absolute;
    left: -1%;
    top: 25%;
    right: auto;
    bottom: 0;
    max-width: 450px;
    z-index: -1;
    @media screen and (max-width:480px) {
        max-width:336px;
        top:28%;
        left: -2%;
    }
}

.shape-four {
    position: absolute;
    right: -1%;
    top: -40%;
    left: auto;
    bottom: 0;
    max-width: 300px;
    z-index: -1;
}

.shape-one,
.shape-two,
.shape-three,
.shape-four {
    // display: none;

    // path {
    //     fill: #f5f5f5;
    // }
}

/*-----------------------------------------------------------------------------------
    ALL SECTIONS
-----------------------------------------------------------------------------------*/
#about,
#stile,
#vorlagen,
#vorteile {
    text-align: center;

    @media only screen and (min-width: 992px) {
        text-align: left;
    }

    img {
        margin: 0 auto;

        @media only screen and (min-width: 992px) {
            margin: initial;
        }
    }

}


/*-----------------------------------------------------------------------------------
    About
-----------------------------------------------------------------------------------*/
#about {

    img.img-center {
        margin: 0 auto;
    }

}


/*-----------------------------------------------------------------------------------
    Stile
-----------------------------------------------------------------------------------*/
#stile {
    .container {

        >div:last-child {
            display: flex;
            flex-wrap: wrap;
        }
        .img-responsive {
            margin:0 auto;
        }
        .arrowdowncontainer {
            margin:0 auto;
            .arrow-down {
                display: block;
                margin: 10px auto;
            }
        }
        @media screen and (max-width:991px) {
            .reversemobile {
                display: flex;
                display:-webkit-flex;
                display:-ms-flex;
                flex-direction: column-reverse;
            }
        }
        .slideUpAnimated.aligntop {
            align-self: start;
            -ms-flex-align: start;
        }
        .row.row-eq-height.alignCenter.narrow-container {
            max-width:900px;
            margin:100px auto;
            @media screen and (max-width:992px) {
                margin:50px auto;
            }
        }
    }
    .narrow-container-illu {
        max-width: 780px;
        margin: 0 auto;
    }
}

/*-----------------------------------------------------------------------------------
    Vorlagen
-----------------------------------------------------------------------------------*/
#vorlagen {
    .container {

        >div:last-child {
            display: flex;
            flex-wrap: wrap;

            >div:first-child {
                order: 2;
                margin: 1.5em auto;

                @media only screen and (min-width: 992px) {
                    order: 1;
                    margin: 0;
                }
            }

            >div:last-child {
                order: 1;

                @media only screen and (min-width: 992px) {
                    order: 2;
                }
            }
        }
    }
}

.video-poster-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    .video-poster {
        position: relative;
        float: left;
        overflow: hidden;
        width: 100%;
        height: auto;
        background: #FFF;
        text-align: center;
        //background: linear-gradient(-45deg, #34495e 0%, #cc6055 100%);

        img {
            position: relative;
            display: block;
            min-height: 100%;
            max-width: 100%;
            opacity: 0.8;
            transition: opacity 0.35s, transform 0.35s;
            object-fit: cover;
        }

        figcaption {
            padding: 2em;
            color: #fff;
            text-transform: uppercase;
            font-size: 1.25em;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        p {
            position: absolute;
            bottom: 0;
            left: 0;
            height: calc(100% - 50px);
            margin: 20px;
            padding: 30px;
            border: 2px solid $gold;
            text-transform: none;
            font-size: 90%;
            color: #333;
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
            transform-origin: 50% -100%;
            transition: opacity 0.35s, transform 0.35s;
        }
    }

    &:hover {
        .video-play-button {
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
            transform-origin: 50% -100%;
        }

        .video-poster {
            img {
                opacity: 0.1;
                transform: scale3d(2, 2, 1);
            }

            figcaption {
                p {
                    opacity: 1;
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    }
}

.video-poster-container {
    .circle {
        border-radius: 50%;
    }

    .player {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -57px 0 0 -57px;
        height: 114px;
        width: 114px;
        background-color: #F2F0F0;
        cursor: pointer;
        transition: transform .3s ease-in-out;
        opacity: 1;
        transform: scale3d(1, 1, 1);
        transform-origin: 50% -100%;
        transition: opacity 0.35s, transform 0.35s;
    }

    .animated {
        /* Smoother animations */
        -webkit-transform: translateZ(0);
    }

    .player .triangle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        margin: -20px 0 0 -10px;
        border-top: 20px solid transparent;
        border-left: 30px solid $brand-primary;
        border-bottom: 20px solid transparent;
        transition: transform .3s ease-in-out;
    }

    .player:hover .triangle {
        border-top: 20px solid transparent;
        border-left: 30px solid $brand-primary;
        border-bottom: 20px solid transparent;
        transform: scale(1.1);
        transform-origin: center;
    }

    .player .buffering {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 104px;
        height: 104px;
        border-right: 2px solid #D3D1D1;
        border-bottom: 2px solid #D3D1D1;
        border-left: 2px solid #F2F0F0;
        border-top: 2px solid #F2F0F0;
        animation: rotate 3s infinite linear;
    }

    &:hover {
        .player {
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
        }
    }
}

// .confirm-text {
//     background-color: #fff;
//     width: 300px;
//     position: absolute;
//     z-index: 10;
//     left: 50%;
//     top: 50%;
//     padding: 20px;
//     transform: translate(-50%, -50%);
//     display: none;
// }

// &:hover {
//     .confirm-text {
//         display: block;
//     }
// }
// }



/* buffer */
@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-ms-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

/*-----------------------------------------------------------------------------------
    Vorteile
-----------------------------------------------------------------------------------*/
.card {
    &.card__padding {
        padding: 1.25em;
    }

    .card__meta {
        margin-bottom: .25em;
    }

    h4 {
        font-size: 1.125em;
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: .125em;
    }

    p {
        font-size: 1.125em;
        line-height: 1.5;
    }
}

.card__image {
    min-height: 100px;
    background-color: transparent;

    &.image-icon {
        margin: 30px auto 0;
        height: 104px;
        width: 104px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:992px) {
            margin: 0px auto 0;
        }

        svg {
            width: 100%;

            path {
                //fill: url(#gradient-horizontal);
            }
        }
    }
}

.vorteile {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .card {
        width: 100%;
        max-width: 460px;
        background: none;

        @media only screen and (min-width: 768px) {
            margin-right: 2em;
            margin-left: 2em;
        }

        @media only screen and (min-width: 992px) {
            margin-right: 4em;
            margin-left: 4em;
        }

        h3 {
            text-transform: none;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Angebot
-----------------------------------------------------------------------------------*/
.bottom-info {
    background: linear-gradient(194.06deg, #E60048 18.05%, #A50034 94.36%);
    box-shadow: 2px 5px 10px rgba(165, 0, 52, 0.1);


    @media only screen and (min-width: 992px) {
        display: flex;
        flex: wrap;
        align-items: center;
        justify-content: space-around;
        padding: 4em 5em 4em 4em;
        max-width: 1066px;
        margin: 0 auto;
    }

    .h3--offer {
        font-weight: 400;
        font-size: 2.25em;
        line-height: 1.3;
        margin: 0 0 1.25em 0;

        @media only screen and (min-width: 992px) {
            font-weight: 400;
            font-size: 35px;
            line-height: 50px;
            text-align: left;
            flex: 0 1 66%;
            margin: 0;
        }
    }
    .h3--offer-main {
        font-weight: 400;
        font-size: 2.25em;
        line-height: 1.3;
        margin: 0 0 1.25em 0;
        text-transform: none;

        @media only screen and (min-width: 992px) {
            font-weight: 400;
            font-size: 42px;
            line-height: 62px;
            text-align: left;
            flex: 0 1 66%;
            margin: 0;
        }
    }
}

/*-----------------------------------------------------------------------------------
    so can be
-----------------------------------------------------------------------------------*/

#so-can-be {
    .img-centered.img-responsive {
    img {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    }
}

#so-can-be + .container {
    margin-top: 150px;
    @media screen and (max-width:992px) {
        margin-top: 35px;
    }
}


/*-----------------------------------------------------------------------------------
    nachhaltigkeit
-----------------------------------------------------------------------------------*/

#nachhaltigkeit,#zusammenarbeit {
    padding-top: 70px;

    .top-buffer-100 {
        margin-top: 50px;
    }
    .row {
        @media screen and (min-width:992px) {
            display: flex;
            display: -webkit-flex;
            display: -ms-flex;
        }
        &.reversemobile {
            @media screen and (max-width: 992px) {
                display: flex;
                display: -webkit-flex;
                display: -ms-flex;
                flex-direction: column-reverse;
            }
        }
        .col-md-3 {
            align-self: center;
            -ms-flex-align: center;
        }
        img {
            margin: 0 auto;
            display: block;
        }
    }
}

#zusammenarbeit {
    p, h4 {
        font-family: "freight-display-pro", Helvetica Neue, Helvetica, Arial, sans-serif;
    }
    p {
        font-weight:500;
    }
    h4 {
        font-weight:700;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 992px) {
        p,h4 {
            font-size: 1.88em;
        }
    }
}

/*-----------------------------------------------------------------------------------
    Kontakt
-----------------------------------------------------------------------------------*/
#kontakt {
    p {
        font-size: 1.25em;
        line-height: 1.75;
    }

    strong {
        font-weight: 700;
    }

    .hd-HeroCta-wrapper {
        padding-left: 10px;
        text-align: center;

        @media only screen and (min-width: 992px) {
            padding-left: 130px;
            text-align: left;
        }
        @media only screen and (max-width: 992px) {
            padding-top: 70px;
        }
    }

    .hd-HeroCta.content {
        strong {
            @media only screen and (min-width: 640px) {
                margin-right: 80px;
            }
        }
    }
}

body.bildnachweise .SiteHeader-inner {
    background-color:#fff;
}

/*-----------------------------------------------------------------------------------
    Footer
-----------------------------------------------------------------------------------*/

.index-success {
    footer {
        .container {
            .row {
                @media only screen and (min-width: 768px) {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

/*-----------------------------------------------------------------------------------
    Scrollanimation Styles
-----------------------------------------------------------------------------------*/

.sm-cta-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        path {
            fill: rgba(105, 176, 207, 0.1);
        }
    }
}

.section {
    transition: opacity .6s ease-in-out, transform .6s ease-in-out;
    transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {

        padding: 70px 0;
    }

    >div {
        z-index: 1;
    }
}

.slideUpAnimated {
    transition: opacity .6s ease-in-out, transform .6s ease-in-out;
    transform: matrix(1, 0, 0, 1, 0, 50);
    opacity: 0;
}

.bg-shape {
    position: fixed;
    top: 50%;
    left: 0;
    width: 150%;
    height: 100%;
    background: url('../img/bg/background-bubble--left.svg') left top no-repeat;
    background-size: 50%;
    transform: translateY(-40%);
    z-index: -1;
}

.bg-shape-2 {
    position: fixed;
    top: 50%;
    right: 0;
    width: 120%;
    height: 100%;
    background: url('../img/bg/background-bubble--right.svg') right top no-repeat;
    background-size: 50%;
    transform: translateY(-40%);
    z-index: -1;
}

.sm-show {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.sm-hide {
    display: none;
}

.sm-opacity {
    transition: opacity .3s ease-in-out;
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {}
}

.sm-opacity-2 {
    transition: opacity 1s ease-in-out;
    opacity: 0;

    @media only screen and (min-width: $sm-breackpoint) {}
}

.sm-fade-in {
    opacity: 1;
}

.panel {
    &.panel-default {
        border: none;
        border-radius: 0;
        margin-bottom: 25px;
    }

    .panel-body {
        background: #FFF;
        padding: 2em;

        p {
            font-size: 1.125em;
        }
    }
}

.panel .panel-heading {
    border-radius: 0;
    padding: 0;
}

.panel .panel-heading a {
    box-shadow: 0 0 40px 0 #e3edfc;
    display: block;
    background: #FFF;
    color: #333;
    padding: 1.5em 4.5em 1.5em 1.5em;
    border-radius: 0;
    text-decoration: none;
    font-size: 20px;
    text-transform: none;
    font-weight: 500;

    &:before,
    &:after {
        width: 16px;
        height: 1px;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 10px;
        left: auto;
        margin-top: -1px;
        background-color: #a50034;
        transition: all .5s;

        @media only screen and (min-width: 480px) {
            width: 30px;
            top: 50%;
            right: 30px;
            height: 2px;
        }
    }

    &:after {
        width: 1px;
        height: 16px;
        right: 17px;
        margin-top: -8px;

        @media only screen and (min-width: 480px) {
            width: 2px;
            height: 30px;
            right: 45px;
            margin-top: -15px;
        }
    }

    &[aria-expanded="true"] {

        &:before,
        &:after {
            transform: rotate(-90deg);
        }

        &:before {
            opacity: 0;
        }
    }
}